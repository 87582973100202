<nb-card>
  <nb-card-header>Selecionar Colunas</nb-card-header>
  <nb-card-body>
    <div *ngIf="source.length <= 0" style="text-align: center">
      Não há colunas registradas
    </div>
    <ng2-smart-table
      *ngIf="source.length > 0"
      [settings]="settings"
      [source]="source"
    >
    </ng2-smart-table>
  </nb-card-body>

  <nb-card-footer>
    <button
      type="button"
      nbButton
      status="primary"
      class="button"
      (click)="onConfirm()"
    >
      Confirmar
    </button>
    <button type="reset" nbButton class="button" (click)="close()">
      Fechar
    </button>
  </nb-card-footer>
</nb-card>
