import { Observable } from "rxjs";
import { BaseModel } from "./bases/base-model";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface AccountType extends BaseModel {
    id?:string;
    name?:string;
}

export abstract class AccountTypeData {
    abstract list(filter:AccountType, pagination:any): Observable<BaseResponse<AccountType[],BaseResponsePagination>>
    abstract update(accountType:AccountType)
    abstract delete(id)
    abstract insert(accountType:AccountType)
}