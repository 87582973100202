import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { NbAuthModule, NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { AuthInterceptor } from './@core/utils/http/auth-interceptor';
import { ToastService } from './@core/utils/toast/toast.service';
import { IConfig } from 'ngx-mask'

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbAuthModule
  ],
  bootstrap: [AppComponent],
  providers: [
    ToastService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router, authService: NbAuthService,toastService:ToastService) {
        return new AuthInterceptor(router, authService, toastService);
      },
      multi: true,
      deps: [Router, NbAuthService, ToastService]
   },
  ]
})
export class AppModule {
}
