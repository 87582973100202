<div class="row">
  <div class="col-md-6">
    <nb-card>
      <nb-card-header>
        <div class="d-flex justify-content-between">
          Módulo
          <div class="action-menu">
            <nb-actions size="small">
              <nb-action icon="plus-outline" (click)="popupModule()"></nb-action>
            </nb-actions>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div *ngIf="modules?.length < 1" style="text-align: center;">Não existem módulos adicionados
        </div>
        <ng2-smart-table [settings]="settingModule" [source]="sourceModule" (delete)="onDeleteModule($event)"
          *ngIf="modules?.length > 0">
        </ng2-smart-table>
      </nb-card-body>
    </nb-card>
  </div>
  <div class="col-md-6">
    <nb-card>
      <nb-card-header>
        <div class="d-flex justify-content-between">
          Funcionalidade
          <div class="action-menu">
            <nb-actions size="small">
              <nb-action icon="plus-outline" (click)="popupFuncionality()"></nb-action>
            </nb-actions>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div *ngIf="funcionalities.length < 1" style="text-align: center;">Não existem funcionalidades
          adicionadas</div>
        <ng2-smart-table [settings]="settingFuncionality" [source]="sourceFunctionality" (delete)="onDeleteFuncionality($event)"
          *ngIf="funcionalities?.length > 0">
        </ng2-smart-table>
      </nb-card-body>
    </nb-card>
  </div>
</div>
