<div class="row" *ngIf="showFilter">
  <div class="col-md-12">
    <nb-card>
      <nb-card-header>
        <div class="row show-grid flex-nowrap" style="white-space: nowrap;">
          <div class="col-md-8">
            <div>Filtro</div>
          </div>
          <div class="col-md-4 action-menu">
            <nb-actions size="small">
              <nb-action style="padding-right: unset;text-align: end;">
                <nb-icon icon="close" pack="myicons" (click)="onShowHideFilter()" style="cursor: pointer;"></nb-icon>
              </nb-action>
            </nb-actions>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <form (ngSubmit)="onList()" #form="ngForm" aria-labelledby="title">
          <div class="row">
            <div class="form-group col-md-12">
              <input nbInput fullWidth [(ngModel)]="filter.name" #name="ngModel" name="name" id="input-name"
                placeholder="Nome do módulo">
            </div>
          </div>
          <button type="submit" nbButton status="primary" class="button">Filtrar</button>
          <button type="reset" nbButton class="button" (click)="onClear(); form.resetForm();">Limpar</button>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<nb-card [nbSpinner]="isLoading" nbSpinnerSize="small" nbSpinnerStatus="primary">
  <nb-card-header>
    <div class="row show-grid flex-nowrap" style="white-space: nowrap;">
      <div class="col-md-8">
        <div>Módulo</div>
      </div>
      <div class="col-md-4 action-menu">
        <nb-actions size="small">
          <nb-action style="padding-right: unset;text-align: end;">
            <div style="display: flex; flex-direction: column; width: 25px; align-self: center;">
              <div (click)="onShowHideFilter()" [ngClass]="(showFilter) ? 'nofilter' : 'filter'"></div>
            </div>
          </nb-action>
        </nb-actions>
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <ng-container *ngIf="isAdded">
      <p class="caption status-danger">
        Módulo já cadastrado.
      </p>
    </ng-container>

    <ng2-smart-table [settings]="settings" [source]="source" (userRowSelect)="onClickRow($event)">
    </ng2-smart-table>
    <ngx-pager [(currentPage)]="filter.currentPage" (currentPageChange)="onList()" [perPage]="filter.pageSize"
      [total]="total"></ngx-pager>
  </nb-card-body>
  <nb-card-footer>
    <button type="reset" nbButton class="button" (click)="close()">Fechar</button>
  </nb-card-footer>
</nb-card>
