import { Observable } from "rxjs";
import { BaseResponse } from "./bases/base-response";
import { Account } from "./account";
import { Bank } from "./bank";

export interface CreditSummary {
  transactions?:CreditSummaryTransaction[];
  total_credit_all_accounts?:number;
  posting_date?:string;
}

export interface CreditSummaryTransaction {
  agency?:string;
  account?:string;
  account_id?:number;
  credit_total?:number;
}

export interface CreditSummaryFilter {
  posting_date:Date;
  account?:Account<Bank>;
  min_amount?:string;
  max_amount?:string;
}

export abstract class CreditSummaryData {
  abstract list(filter:CreditSummaryFilter): Observable<BaseResponse<CreditSummary,any>>;
}
