import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse } from '../data/bases/base-response';
import { CommonData, Version } from '../data/common';

@Injectable()
export class CommonService extends CommonData {
  private baseUrl = environment.services.financial.baseUrl;

  constructor(private http: HttpClient) { super() }

  getVersion(): Observable<BaseResponse<Version[],any>> {
    return this.http.get<BaseResponse<Version[],any>>(`${this.baseUrl}/api/common/tag/`)
  }
}
