import { environment } from "../../../../../../environments/environment";

export const ModuleSelectTableSettings = {
    pager: {
        perPage: environment.pages.permissions.recordsPerPage,
        display: false
    },
    mode: 'external',
    hideSubHeader: true,
    selectedRowIndex: -1,
    actions: {
        columnTitle: "",
        add: false,
        edit: false,
        delete: false,
        position: 'right'
    },
    noDataMessage: "Não há módulo cadastrado",
    columns: {
        id: {
            title: 'Id',
            hide: true
        },
        name: {
            title: 'Módulo',
        },
        // level: {
        //     title: 'Nível de Permissão',
        //     type: 'custom',
        //     renderComponent: ActionsDropdownComponent,
        //     onComponentInitFunction(instance) {
        //         instance.save.subscribe(row => {
        //             // alert(`Id: ${row.id}`)
        //         });
        //     }
        // }
    }
}
