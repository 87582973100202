import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PersonData } from "../data/person";

@Injectable()
export class PjService extends PersonData {
    // private baseUrl = environment.services.

    constructor(private http: HttpClient) { super() }

}