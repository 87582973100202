import { Component } from '@angular/core';
import { AuthService } from '../../@core/service/auth.service';
import { ToastService } from '../../@core/utils/toast/toast.service';
import { ComponentStatus } from '../../@core/utils/enum/component-status.enum';
import { BaseResponse, BaseResponsePagination } from '../../@core/data/bases/base-response';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ngx-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
})
export class RequestPasswordComponent {
  request:any = {email:""}
  submitted:boolean=false
  errors=[]
  isLoading:boolean = false

  constructor(
    private service:AuthService,
    private toast:ToastService,
    private router:Router,
    private spinner:NgxSpinnerService
  ) { }

  onRequest(form:NgForm) {
    this.submitted = true;
    this.spinner.show()


    merge()
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoading = true;
        return this.service.requestPassword(this.request.email)
        }),
      map(data => {
        this.isLoading = false;
        return data;
      })
    )
    .subscribe(
      () => {
        this.toast.show("Nova senha solicitada",
            "Solicitação realizada com sucesso! Verifique o seu e-mail.",
            ComponentStatus.Success)

        setTimeout(() => {
          this.spinner.hide()
          return this.router.navigateByUrl('/auth/login');
        }, 1000);
      },
      (result:BaseResponse<null,BaseResponsePagination>) => {
        this.spinner.hide()
        this.submitted = false;
      }
    )
  }
}
