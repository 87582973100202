import { Injectable } from "@angular/core";
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from "@ngxs/store";

import { SaveColumns } from "./column-selector.action";
import { ColumnSelectorStateModel } from "./conlumn-selector.interface";
import {
  COLUMN_SELECTOR_STATE_NAME,
  initialState,
} from "./column-selector.contant";
import { ScreenTables } from "../../utils/enum/screen-tables.enum";

@State<ColumnSelectorStateModel>({
  name: COLUMN_SELECTOR_STATE_NAME,
  defaults: initialState,
})
@Injectable()
export class ColumnSelectorState {
  public static readonly TOKEN = COLUMN_SELECTOR_STATE_NAME;

  @Action(SaveColumns)
  saveColumns(
    ctx: StateContext<ColumnSelectorStateModel>,
    { table, columns }: SaveColumns
  ) {
    ctx.patchState({ [table]: columns });
  }

  @Selector([ColumnSelectorState])
  static state(state: ColumnSelectorStateModel): ColumnSelectorStateModel {
    return state;
  }
  static getColumnsByTable(table: ScreenTables) {
    return createSelector(
      [ColumnSelectorState],
      (state: ColumnSelectorStateModel) => {
        return state[table] || [];
      }
    );
  }
}
