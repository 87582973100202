import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Module, ModuleData, ModuleFilter } from '../../../../../@core/data/module';
import { ModuleSelectTableSettings } from './module-select-tablesettings';
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { BaseResponse, BaseResponsePagination } from '../../../../../@core/data/bases/base-response';

@Component({
  selector: 'ngx-module-select',
  templateUrl: './module-select.component.html',
  styleUrls: ['./module-select.component.scss']
})
export class ModuleSelectComponent {
  isLoading: boolean = false
  showFilter: boolean = false;
  Module: Module[] = []
  list:Module[]
  source: LocalDataSource = new LocalDataSource();
  settings = ModuleSelectTableSettings;
  filter:ModuleFilter = { isActive: true, pageSize: this.settings.pager.perPage, currentPage: 1 }
  total: number;
  @Input() contains:Module[] = []
  isAdded:boolean = false

  constructor(
    private service: ModuleData,
    private cd: ChangeDetectorRef,
    @Optional() protected ref: NbDialogRef<ModuleSelectComponent>,
  ) { }

  onList(){
    merge()
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoading = true;
        return this.service.list(this.filter)
        }),
      map(data => {
        this.isLoading = false;
        return data;
      }),
      catchError(err => {
        return err;
      })
    )
    .subscribe(
      (result:BaseResponse<Module[],BaseResponsePagination>) => {
      this.total = result.pagination?.count
      this.Module = result.result
      this.list = result.result
      this.source.load(this.Module)
    },
    () => {},
    () => this.showFilter = false)

    this.cd.detectChanges()
  }

  onShowHideFilter() {
    if (this.showFilter)
      this.showFilter = false
    else
      this.showFilter = true
  }

  onClickRow(event){
    this.isAdded = false

    if (event.data) {
      if (this.contains.find(ug => ug.id == event.data.id)) {
        this.isAdded = true
        return
      }

      this.ref.close(event.data)
    }
  }

  close(){
    this.ref.close()
  }

  onClear(){
    this.filter = {}
    this.onList()
  }
}
