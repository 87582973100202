import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Document, DocumentData } from "../data/document";
import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";

@Injectable()
export class DocumentService extends DocumentData {
    private baseUrl = environment.services.accessControl.baseUrl;
  
    constructor(private http:HttpClient) { super() }

    list(filter:Document, pagination:any) : Observable<BaseResponse<Document[],BaseResponsePagination>> {
        var params = new HttpParams()
        params = params.set('currentPage', pagination.currentPage)
        params = params.set('pageSize', pagination.pageSize)

        if(filter.id)
            params = params.set('id', filter.id)
        if(filter.name)
            params = params.set('name', filter.name)
        if(filter.personType)
            params = params.set('personType', filter.personType)

        return this.http.get<BaseResponse<Document[],BaseResponsePagination>>(`${this.baseUrl}/api/v1/document`, { params })
    }

    update(document:Document){
        return this.http.put(`${this.baseUrl}/api/v1/document/${document.id}`, document)
    }

    delete(id){
        return this.http.delete(`${this.baseUrl}/api/v1/document/${id}`, id)
    }

    insert(document:Document){
        return this.http.post(`${this.baseUrl}/api/v1/document/`,document)
    }
}
