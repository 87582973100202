import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";
import { ParamData, ParamFinancial, ParamFinancialValue, ParamGeneral } from "../data/params";

@Injectable()
export class ParamService extends ParamData {
  private accessControlBaseUrl = environment.services.accessControl.baseUrl;
  private financialBaseUrl = environment.services.financial.baseUrl;

  constructor(private http: HttpClient) { super() }

  getGeneral(): Observable<BaseResponse<ParamGeneral,BaseResponsePagination>> {
    return this.http.get<BaseResponse<ParamGeneral,BaseResponsePagination>>(`${this.accessControlBaseUrl}/api/v1/param`)
  }

  updateGeneral(id, param: ParamGeneral) {
    return this.http.put(`${this.accessControlBaseUrl}/api/v1/param/${id}/`, param)
  }

  getFinancial(): Observable<BaseResponse<ParamFinancial,any>> {
    return this.http.get<BaseResponse<ParamFinancial,any>>(`${this.financialBaseUrl}/api/params/last/`)
  }

  updateFinancial(param:ParamFinancial) {
    return this.http.post(`${this.financialBaseUrl}/api/params/`, param)
  }
}

