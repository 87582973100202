import { Observable } from "rxjs";
import { PersonType } from "../utils/enum/person-type.enum";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { Document } from "../data/document";

export interface Profiles extends Document {
    id?:string;
    name?:string;
    personType?:PersonType
    selected?:boolean;
}

export abstract class ProfilesData {
    abstract list(filter:Profiles, pagination:any): Observable<BaseResponse<Profiles[],BaseResponsePagination>> 
}