import { Observable } from "rxjs";
import { BaseModel } from "./bases/base-model";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface Restriction extends BaseModel {
    id?:string;
    name?:string;
}

export abstract class RestrictionData {
    abstract list(filter:Restriction, pagination:any): Observable<BaseResponse<Restriction[],BaseResponsePagination>>
    abstract update(Restriction:Restriction)
    abstract delete(id)
    abstract insert(Restriction:Restriction)
}