import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { environment } from "../../../environments/environment";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule } from '@ngxs/store';
import { ColumnSelectorState } from "./column-selector/column-selector.state";

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forRoot([ColumnSelectorState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [ColumnSelectorState.TOKEN],
      storage: StorageOption.LocalStorage,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    CommonModule,
  ],
})
export class StoreModule {}
