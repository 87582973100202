import { Injectable } from '@angular/core';
import { AuthData, AuthManifest, Authorization } from '../data/auth';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResetPassword } from '../data/reset-password';
import { BaseResponse } from '../data/bases/base-response';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService extends AuthData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http: HttpClient,
    protected router: Router,
  ) { super() }

  requestPassword(email: string) {
    var params = new HttpParams()

    if (email)
      params = params.set("email", email)

    return this.http.post(`${this.baseUrl}/api/v1/authentication/request-password/`, null, { params })
  }

  resetPassword(resetPassword: ResetPassword) {
    var params = new HttpParams()

    if (resetPassword.newPassword1)
      params = params.set("newPassword1", resetPassword.newPassword1)

    if (resetPassword.newPassword2)
      params = params.set("newPassword2", resetPassword.newPassword2)

    if (resetPassword.token)
      params = params.set("token", resetPassword.token)

    return this.http.put(`${this.baseUrl}/api/v1/authentication/reset/`, null, { params })
  }

  authorization(alias: string, screen: 'list' | 'form' | 'select' = 'list'): Observable<AuthManifest> {
    let auth: AuthManifest = { delete: false, select: false, add: false, edit: false };

    const params = new HttpParams().set('alias', alias);

    return this.http.get<Authorization>(`${this.baseUrl}/api/v1/authorization?alias=${alias}`).pipe(
      map((response: Authorization) => {
        
        switch (response.auth.user) {
          case 0:
            auth = { delete: false, select: true, add: false, edit: false };
            break;
          case 1:
            auth = { delete: false, select: true, add: true, edit: true };
            break;
          case 2:
            auth = { delete: true, select: true, add: true, edit: true };
            break;
          default:
            auth = { delete: false, select: false, add: false, edit: false };
            break;
        }

        if (screen === 'form' && !auth.add) {
          this.router.navigateByUrl("/pages/404");
        }

        auth.level = response.auth.user;
        return auth;
      })
    );
  }

}

