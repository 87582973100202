import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { NbIconLibraries } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService, NbAuthToken } from '@nebular/auth';
import { map, takeUntil } from 'rxjs/operators';
import { Account } from '../../../@core/data/account';
import { Router } from '@angular/router';
import { Bank } from '../../../@core/data/bank';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnDestroy,OnInit {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any = { name: 'Dan Lustosa', picture: 'assets/images/user.png' };
  account:Account<Bank>

  userMenu = [
    {
      title: 'Meu perfil',
      link:'/pages/access/my-profile'
    },
    {
      title: 'Sair',
      link: '/auth/logout'
    }
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private iconLibraries: NbIconLibraries,
              private authService: NbAuthService,
              private breakpointService: NbMediaBreakpointsService,
              private router:Router,
              ) {
                this.iconLibraries.registerFontPack('font-awesome', { packClass: 'fa' });
  }

  ngOnInit() {
    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = {
          name: token.getPayload().name,
          picture: 'assets/images/user.png'
        };
      }
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
