import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";
import { UserGroup, UserGroupData } from "../data/user-group";


@Injectable()
export class UserGroupService extends UserGroupData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http:HttpClient) { super() }

  list(filter:UserGroup, pagination:any): Observable<BaseResponse<UserGroup[],BaseResponsePagination>> {
    var params = new HttpParams()
    params = params.set('currentPage', pagination.currentPage)
    params = params.set('pageSize', pagination.pageSize)

    if(filter.id)
      params = params.set('id', filter.id)

    if(filter.name)
      params = params.set('name', filter.name)

    if(filter.area)
      params = params.set('area', filter.area)

    if (filter.isActive)
      params = params.set('isActive', filter.isActive)

    params = params.set('orderBy','')
    params = params.set('sortedBy','')

    return this.http.get<BaseResponse<UserGroup[],BaseResponsePagination>>(`${this.baseUrl}/api/v1/user_group`, { params })
  }

  insert(userGroup:UserGroup){
    return this.http.post(`${this.baseUrl}/api/v1/user_group`,userGroup)
  }

  delete(id){
    return this.http.delete(`${this.baseUrl}/api/v1/user_group/${id}`,id)
  }

  update(userGroup:UserGroup){
    return this.http.put(`${this.baseUrl}/api/v1/user_group/${userGroup.id}`,userGroup)
  }

}
