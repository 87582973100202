import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse } from '../data/bases/base-response';
import { UserAccountData } from '../data/user-account';

@Injectable()
export class UserAccountService extends UserAccountData {
  private baseUrl = environment.services.financial.baseUrl;

  constructor(private http:HttpClient) { super() }

  insert(userId:number, accounts:any) : Observable<any> {
    return this.http.post<BaseResponse<any,any>>(`${this.baseUrl}/api/user_account/${userId}/associate/`, accounts)
  }

  delete(userId:number, accounts:any) : Observable<any> {
    return this.http.post<BaseResponse<any,any>>(`${this.baseUrl}/api/user_account/${userId}/disassociate/`, accounts)
  }

}
