import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ClientData } from "../data/client";

@Injectable()
export class ClientService extends ClientData {
    // private baseUrl = environment.services.

    constructor(private http: HttpClient) { super() }

}