import { Component, Input, Optional } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Email } from '../../../../@core/data/bases/base-model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-email-select',
  templateUrl: './email-select.component.html',
  styleUrls: ['./email-select.component.scss']
})
export class EmailSelectComponent {
  submitted: boolean = false;
  email:Email = {address: ''};
  @Input() contains: any;

  constructor(
    @Optional() protected ref: NbDialogRef<EmailSelectComponent>,
  ) { }

  confirmEmail(form:NgForm){
    if (this.contains.find(email => email.address == this.email.address)) {
      form.controls.address.setErrors({ invalid: true })
      return
    }

    this.ref.close(this.email)
  }

  close(){
    this.ref.close()
  }
}
