import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthService } from '@nebular/auth';
import { LocalStorageData } from '../../@core/data/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  redirectDelay: number = 1000;

  constructor(protected service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected router: Router,
              protected localStorage:LocalStorageData,
              private spinner:NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show()
    this.logout()
  }

  logout(): void {
    this.localStorage.remove('auth_app_token')
    setTimeout(() => {
      this.spinner.hide()
      return this.router.navigateByUrl('/auth/login');
    }, this.redirectDelay);
  }
}
