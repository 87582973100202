export const environment = {
  production: false,
  services: {
    financial: {
      baseUrl: "https://dev.financeiro.projetamais.com.br",
    },
    accessControl: {
      // baseUrl: "https://localhost:7242"
      baseUrl: "https://dev.controleacesso.projetamais.com.br",
    },
  },
  toastPosition: "top-right", //TOP_RIGHT="top-right",TOP_LEFT="top-left",BOTTOM_RIGHT="bottom-right",BOTTOM_LEFT="bottom-left"
  pages: {
    selects: {
      recordsPerPage: 5,
    },
    user: {
      recordsPerPage: 10,
    },
    account: {
      recordsPerPage: 10,
    },
    userGroup: {
      recordsPerPage: 10,
    },
    credit: {
      recordsPerPage: 50,
    },
    banks: {
      recordsPerPage: 500,
    },
    permissions: {
      recordsPerPage: 1000,
    },
    client: {
      recordsPerPage: 10,
    },
    contact: {
      recordsPerPage: 10,
    },
    document: {
      recordsPerPage: 10,
    },
    restriction: {
      recordsPerPage: 10,
    },
  },
  financial: {
    banks: [
      {
        code: "001",
        settings: {
          transaction: {
            showStatusTransaction: true,
            allowSync: true,
            importOFX: false,
          },
        },
      },
      {
        code: "041",
        settings: {
          transaction: {
            showStatusTransaction: false,
            allowSync: false,
            importOFX: true,
          },
        },
      },
    ],
  },
};
