import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { License } from "./bases/base-model";

export interface UserGroup {
    id?:string;
    name?:string;
    area?:string;
    isActive?:boolean;
    license?:License;
}

export enum UserGroupEnum {
    GOV = "Governança",
    FIN = "Financeiro",
    PRJ = "Gestão de Projetos",
    BOL = "Bolsas",
    CPR = "Compras",
    PRC = "Prestação de Contas",
    ADM = "Administração",
    JUR = "Jurídico",
    RHH = "RH"
}

export abstract class UserGroupData {
    abstract list(filter:UserGroup, pagination:any): Observable<BaseResponse<UserGroup[],BaseResponsePagination>>
    abstract insert(userGroup:UserGroup);
    abstract delete(id);
    abstract update(userGroup:UserGroup)
}
