<form (ngSubmit)="confirmContact(form)" #form="ngForm" aria-labelledby="title">
  <nb-card>
    <nb-card-header>Adicionar contato</nb-card-header>
    <nb-card-body>

      <label id="labelName" class="label">Nome</label>
      <input nbInput fullWidth [(ngModel)]="contact.name" #contactName="ngModel" name="contactName" id="input-name"
        placeholder="Nome" autofocus required="true"
        [status]="contactName.dirty ? (contactName.invalid  ? 'danger' : 'success') : 'basic'"
        [attr.aria-invalid]="contactName.invalid && contactName.touched ? true : null">
      <ng-container *ngIf="contactName.invalid && contactName.touched">
        <p class="caption status-danger" *ngIf="contactName.errors?.required">
          Nome é obrigatório
        </p>
      </ng-container>

      <label id="labelCpf" class="label">CPF</label>
      <input nbInput fullWidth [(ngModel)]="contact.document" #document="ngModel" name="document" id="input-document"
        placeholder="CPF" autofocus>

      <label id="labelEmail" class="label">E-mail</label>
      <input nbInput fullWidth [(ngModel)]="contact.email" #email="ngModel" name="email" id="input-email"
        placeholder="E-mail" autofocus>

      <label id="labelCellphone" class="label">Celular</label>
      <input nbInput fullWidth [(ngModel)]="contact.cellphone" #cellphone="ngModel" name="cellphone"
        id="input-cellphone" placeholder="Celular" autofocus mask="(00) 00000-0000">

      <label id="labelDepartment" class="label">Departamento</label>
      <input nbInput fullWidth [(ngModel)]="contact.department" #department="ngModel" name="department"
        id="input-department" placeholder="Departamento" autofocus>

      <label id="labelFunction" class="label">Função</label>
      <input nbInput fullWidth [(ngModel)]="contact.function" #function="ngModel" name="function" id="input-function"
        placeholder="Função" autofocus>

    </nb-card-body>

    <nb-card-footer>
      <ng-container *ngIf="isAdded">
        <p class="caption status-danger">
          Contato já cadastrado.
        </p>
      </ng-container>

      <button type="submit" nbButton status="primary" class="button" [disabled]="submitted || !form.valid"
        [class.btn-pulse]="submitted">Confirmar</button>
      <button type="reset" nbButton class="button" (click)="close()">Fechar</button>
    </nb-card-footer>
  </nb-card>
</form>