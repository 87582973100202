export enum ToastBodyEnum {
  Save = 'Registro inserido com sucesso',
  SaveMultiple = 'Registros inseridos com sucesso',
  Delete = 'Registro deletado com sucesso',
  Error = "Ocorreu um erro inesperado, contate o administrador.",
  Update = "Registro alterado com sucesso"
}

export enum ToastTitleEnum {
  Save = 'Registro salvo',
  SaveMultiple = 'Registros salvos',
  Delete = 'Registro deletado',
  Error = "Erro inesperado",
  Update = "Registro alterado"
}

export enum DialogTitle {
  Delete = 'Deletar registro',
  LogoutWarning = 'Aviso de logout',
}

export enum DialogDescription {
  Delete = 'Deseja realmente deletar este registro?',
}
