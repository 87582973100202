import { NgModule } from '@angular/core';
import { ThemeModule } from '../@theme/theme.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { NbAlertModule, NbButtonModule, NbCheckboxModule, NbFormFieldModule, NbIconModule, NbInputModule, NbSpinnerModule } from '@nebular/theme';
import { RequestPasswordComponent } from './request-password/request-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LogoutComponent } from './logout/logout.component';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../@core/service/auth.service';
import { ToastService } from '../@core/utils/toast/toast.service';
import { NbAuthService } from '@nebular/auth';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    AuthRoutingModule,
    ThemeModule,
    NbButtonModule,
    NbInputModule,
    NbAlertModule,
    NbCheckboxModule,
    FormsModule,
    NbFormFieldModule,
    NbIconModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    NbSpinnerModule
  ],
  declarations: [
    LoginComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    LogoutComponent
  ],
  providers: [
    AuthService,
    ToastService,
    NbAuthService
  ]
})
export class AuthModule {
}
