import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { BaseFilter } from "./bases/base-filter";
import { BaseModel } from "./bases/base-model";
import { Module } from "./module";

export interface Funcionality extends BaseModel {
    id?:string;
    name?:string;
    level?:number;
    module?:Module;
}

export interface FuncionalityFilter extends Funcionality, BaseFilter {
}

export abstract class FuncionalityData {
    abstract insert(funcionality:Funcionality)
    abstract update(funcionality:Funcionality)
    abstract list(filter:FuncionalityFilter): Observable<BaseResponse<Funcionality[],BaseResponsePagination>>
    abstract delete(id)
  }
