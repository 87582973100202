import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseResponse } from '../data/bases/base-response';
import { Observable } from 'rxjs';
import { toBackendDateFormat } from '../utils/common/datetime-utils';
import { CreditSummary, CreditSummaryData, CreditSummaryFilter } from '../data/credit-summary';


@Injectable()
export class CreditSummaryService extends CreditSummaryData {
  private baseUrl = environment.services.financial.baseUrl;

  constructor(private http:HttpClient) { super() }

  list(filter:CreditSummaryFilter): Observable<BaseResponse<CreditSummary,any>> {
    var params = new HttpParams()
    params = params.set('posting_date', toBackendDateFormat(filter.posting_date))

    if (filter.account && filter.account.id !== 0)
      params = params.set('account_id', filter.account.id)

    if (filter.max_amount)
      params = params.set('max_amount', filter.max_amount)

    if (filter.min_amount)
      params = params.set('min_amount', filter.min_amount)

    return this.http.get<BaseResponse<CreditSummary,any>>(`${this.baseUrl}/api/transaction/summary/`, { params })
  }
}
