import { environment } from "../../../../../../environments/environment";

export const FuncionalitySelectTableSettings = {
    pager: {
        perPage: environment.pages.permissions.recordsPerPage,
        display: false
    },
    mode: 'external',
    hideSubHeader: true,
    selectedRowIndex: -1,
    actions: {
        columnTitle: "",
        add: false,
        edit: false,
        delete: false,
        position: 'right'
    },
    noDataMessage: "Não há funcionalidade cadastrada",
    columns: {
        id: {
            title: 'Id',
            hide: true
        },
        module: {
          title: 'Módulo',
          valuePrepareFunction :(cell, row) =>{
            return row.module.name;
          }
        },
        name: {
            title: 'Funcionalidade',
        }
    }
}
