import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { BaseResponse, BaseResponsePagination } from '../../../../../@core/data/bases/base-response';
import { Funcionality, FuncionalityData, FuncionalityFilter } from '../../../../../@core/data/funcionality';
import { FuncionalitySelectTableSettings } from './funcionality-select-tablesettings';
import { Module, ModuleData } from '../../../../../@core/data/module';

@Component({
  selector: 'ngx-funcionality-select',
  templateUrl: './funcionality-select.component.html',
  styleUrls: ['./funcionality-select.component.scss']
})
export class FuncionalitySelectComponent  {
  isLoading: boolean = false
  showFilter: boolean = false;
  funcionality: Funcionality[] = []
  list:Funcionality[]
  source: LocalDataSource = new LocalDataSource();
  settings = FuncionalitySelectTableSettings;
  filter:FuncionalityFilter = {
    module: {
      id: ''
    },
    isActive: true,
    pageSize: this.settings.pager.perPage,
    currentPage: 1,
  }
  total: number;
  @Input() contains:Funcionality[] = []
  isAdded:boolean = false
  modules:Module[] = []

  constructor(
    private service: FuncionalityData,
    private moduleService: ModuleData,
    private cd: ChangeDetectorRef,
    @Optional() protected ref: NbDialogRef<FuncionalitySelectComponent>,
  ) {
    this.moduleService.list({})
      .subscribe((result) => {
        this.modules = result.result
      })
   }

  onList(){
    merge()
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoading = true;
        return this.service.list(this.filter)
        }),
      map(data => {
        this.isLoading = false;
        return data;
      }),
      catchError(err => {
        return err;
      })
    )
    .subscribe(
    (result:BaseResponse<Funcionality[],BaseResponsePagination>) => {
      this.total = result.pagination?.count
      this.funcionality = result.result
      this.list = result.result
      this.source.load(this.funcionality)
    },
    () => {},
    () => this.showFilter = false)

    this.cd.detectChanges()
  }

  onShowHideFilter() {
    if (this.showFilter)
      this.showFilter = false
    else
      this.showFilter = true
  }

  onClickRow(event){
    this.isAdded = false

    if (event.data) {
      if (this.contains.find(ug => ug.id == event.data.id)) {
        this.isAdded = true
        return
      }

      this.ref.close(event.data)
    }
  }

  close(){
    this.ref.close()
  }

  onClear(){
    this.filter = {
      module: {
        id: ''
      },
      isActive: true,
      pageSize: this.settings.pager.perPage,
      currentPage: 1,
    }
    this.onList()
    this.onShowHideFilter()
  }

}
