import { Injectable } from '@angular/core';
import { User, UserData, UserFilter } from '../data/user';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { setBaseFilter } from '../utils/http/base-http-filter';

@Injectable()
export class UserService extends UserData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http:HttpClient) { super() }

  insert(user:User){
    return this.http.post(`${this.baseUrl}/api/v1/user/`,user)
  }

  update(user:User) {
    return this.http.put(`${this.baseUrl}/api/v1/user/${user.id}/`,user)
  }

  delete(id){
    return this.http.delete(`${this.baseUrl}/api/v1/user/${id}/`,id)
  }

  list(filter:UserFilter, pagination:any): Observable<BaseResponse<User[],BaseResponsePagination>> {
    var params = setBaseFilter(filter)
    params = params.set('currentPage', pagination.currentPage)
    params = params.set('pageSize', pagination.pageSize)

    if (filter.id)
      params = params.set('id', filter.id)

    if (filter.person.name)
      params = params.set('name', filter.person.name)

    if (filter.email)
      params = params.set('email', filter.email)

    if (filter.isActive)
      params = params.set('isActive', filter.isActive)

    return this.http.get<BaseResponse<User[],BaseResponsePagination>>(`${this.baseUrl}/api/v1/user/`, { params })
  }
}
