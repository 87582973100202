import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LicenseModule } from "../../../../@core/data/bases/base-model";

@Component({
    selector: 'ngx-actions-dropdown',
    template: `
        <nb-select (selectedChange)="onSelect($event)" [selected]="value.level" id="select-level" placeholder="Nível" fullWidth>
          <nb-option *ngFor="let option of options" [value]="option.value">{{ option.description }}</nb-option>
        </nb-select>
    `,
})
export class ActionsDropdownComponent {
  @Input() value: any;
  @Input() rowData: LicenseModule;
  @Output() valueChange = new EventEmitter<{ value:any, rowData:LicenseModule }>();
  options: any[] = [
    {
      value: 0,
      description: "Leitura"
    },
    {
      value: 1,
      description: "Leitura/edição"
    },
    {
      value: 2,
      description: "Administrador"
    },
  ];

  onSelect(value: number) {
    this.valueChange.emit({ value, rowData: this.rowData });
  }
}
