import { Component, Input, OnInit, Optional } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { BasicExampleMultiSelectComponent } from "./column-selector-tablesettings";
import { ScreenTables } from "../../../../@core/utils/enum/screen-tables.enum";
import { Store } from "@ngxs/store";
import { ColumnSelectorState } from "../../../../@core/store/column-selector/column-selector.state";
import { SaveColumns } from "../../../../@core/store/column-selector/column-selector.action";
import { ToastService } from "../../../../@core/utils/toast/toast.service";

interface Column {
  [key: string]: { title: string };
}

@Component({
  selector: "ngx-column-selector-select",
  templateUrl: "./column-selector-select.component.html",
  styleUrls: ["./column-selector-select.component.scss"],
})
export class ColumnSelectorSelectComponent implements OnInit {
  @Input() table: ScreenTables;
  @Input() columns: Column;

  settings = BasicExampleMultiSelectComponent.settings;
  source = [];

  constructor(
    @Optional() protected ref: NbDialogRef<ColumnSelectorSelectComponent>,
    private toast: ToastService,
    private store: Store
  ) {}

  ngOnInit() {
    if (!this.table || !this.columns)
      throw new Error("Parâmetro 'table' não informado.");

    if (!this.columns) throw new Error("Parâmetro 'columns' não informado.");

    const selectedRows = this.store.selectSnapshot(
      ColumnSelectorState.getColumnsByTable(this.table)
    );
    this.source = Object.keys(this.columns).map((key) => ({
      name: key,
      title: this.columns[key].title,
      selected: selectedRows.includes(key) || selectedRows.length <= 0,
    }));
  }
  onConfirm() {

    const selecteds = this.source
      .filter((row) => row.selected)
      .map((row) => row.name);

    if (selecteds.length < 1) {
      this.toast.errorTitleMsg("Erro", "Selecione pelo menos 1 coluna");
      return;
    }

    this.store.dispatch(new SaveColumns(this.table, selecteds));
    this.close();
  }

  close() {
    this.ref.close();
  }
}
