import { Address } from "./address";
import { BankInformations, BaseModel, Email } from "./bases/base-model";
import { Restriction } from "./restriction";

export interface Person {
  id?:string;
  type?:string;
  name?:string; // PF: Nome completo | PJ: Razão social
  alias?:string; //PF: Apelido ou nome social |  PJ: Nome fantasia
  abbreviation?:string; //PF e PJ: abreviatura 
  document?:string; // PF: CPF | PJ: CNPJ
  cellphone?:string;
  isActive?:boolean;
  emails?:Email[];
  bankData?:PersonBankData[];
  contact?:Contact[];
  address?:Address[];
  personDocument?:PersonDocument[];
}

interface PersonBankData extends BankInformations {
  id?:string;
  personId?:string;
}

export interface Pj extends BaseModel {
  person?:Person;
  stateRegistration?: string;
  municipalRegistration?: string;
  type?: string;
  segment?: string;
  activity?: string;
  situation?: string;
  nationality?:string;
  sector?:string;
  scope?:string;
  socialNetwork?:string;
  website?:string;
  status?:string;
  reason?:string;
  supplier?:boolean;
  financier?:boolean;
  apoiada?:boolean;

  mecRegistration?:string;
  reimbursementLegislation?:string;
  ordinanceDate?:string;
  ordinanceLink?:string;
  initialTerm?:string;
  finalTerm?:string;
  restriction?:PersonRestriction[];
}

export interface Contact {
  name?:string;
  document?:string;
  cellphone?:string;
  email?:string;
  department?:string;
  function?:string;
}

export interface Partner {
  name?:string;
  document?:string;
  cellphone?:string;
  email?:string;
}

export interface PersonRestriction {
  pj?:Pj;
  restriction?:Restriction;
  name?:string;
}

export interface PersonDocument {
  person?:Person;
  document?:Document;
  expiredDate?:Date;
  file?:string;
}

export abstract class PersonData {
}
