import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface Param {
    general:ParamGeneral,
    financial:ParamFinancial
}

export interface ParamGeneral {
    id?:string;
    timeZone?:string;
    senderEmailAddress?:string;
    senderHost?:string;
    senderPort?:string;
    senderIsTsl?:boolean;
    senderUser?:string;
    senderPassword?:string;
}

export interface ParamFinancial {
    value?:ParamFinancialValue;
    is_active?: boolean;
}

export interface ParamFinancialValue {
    email_users?:string;
    bb_key?:string;
    credit_users?:string[];
    debit_users?:string[];
}

export abstract class ParamData {
    abstract getGeneral(): Observable<BaseResponse<ParamGeneral,BaseResponsePagination>>
    abstract updateGeneral(id, param:ParamGeneral)
    abstract getFinancial(): Observable<BaseResponse<ParamFinancial,any>>
    abstract updateFinancial(param:ParamFinancial)
}
