import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Module, ModuleData, ModuleFilter } from "../data/module";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";
import { Observable } from "rxjs";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class ModuleService extends ModuleData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http:HttpClient) { super() }

  insert(module:Module){
    return this.http.post(`${this.baseUrl}/api/v1/module/`,module)
  }

  update(module:Module) {
    return this.http.put(`${this.baseUrl}/api/v1/module/${module.id}/`,module)
  }

  delete(id){
    return this.http.delete(`${this.baseUrl}/api/v1/module/${id}/`,id)
  }

  list(filter:ModuleFilter): Observable<BaseResponse<Module[],BaseResponsePagination>> {
    var params = setBaseFilter(filter)

    if (filter.id)
      params = params.set('id', filter.id)

    if (filter.name)
      params = params.set('name', filter.name)

    if (filter.isActive)
      params = params.set('isActive', filter.isActive)

    return this.http.get<BaseResponse<Module[],BaseResponsePagination>>(`${this.baseUrl}/api/v1/module/`, { params })
  }
}
