import { ResetPassword } from "./reset-password";
import { User } from "./user";

export interface Authorization {
  user:User;
  auth:AuthLevel;
}

interface AuthLevel {
  funcionality:number;
  user:number;
}

export interface AuthManifest {
  level?:number;
  add?:boolean;
  delete?:boolean;
  edit?:boolean;
  select?:boolean;
}

export abstract class AuthData {
  abstract requestPassword(email:string)
  abstract resetPassword(resetPassword: ResetPassword)
  abstract authorization(alias: string, screen:string)
}
