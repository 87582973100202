import { Injectable } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { ContextData } from '../data/context';
import { ProfileEnum, User } from '../data/user';

@Injectable()
export class ContextService extends ContextData {
  constructor(private authService: NbAuthService) { super() }

  get():User {
    var user:User;
    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        user = {
          id: token.getPayload().id,
          email: token.getPayload().email,
          person: {
            name: token.getPayload().name
          },
          master: token.getPayload().master
        }
      }
    });
    return user;
  }

  isMaster():boolean {
    const user = this.get()
    return user.master;
  }
}
