import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Restriction, RestrictionData } from "../data/restriction";
import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";

@Injectable()
export class RestrictionService extends RestrictionData {
    private baseUrl = environment.services.accessControl.baseUrl;
  
    constructor(private http:HttpClient) { super() }

    list(filter:Restriction, pagination:any) : Observable<BaseResponse<Restriction[],BaseResponsePagination>> {
        var params = new HttpParams()
        params = params.set('currentPage', pagination.currentPage)
        params = params.set('pageSize', pagination.pageSize)

        if(filter.id)
            params = params.set('id', filter.id)      
        if(filter.name)
            params = params.set('name', filter.name)

        return this.http.get<BaseResponse<Restriction[],BaseResponsePagination>>(`${this.baseUrl}/api/v1/restriction`, { params })
    }
     
    update(restriction:Restriction){
        return this.http.put(`${this.baseUrl}/api/v1/restriction/${restriction.id}`, restriction)
    }

    delete(id){
        return this.http.delete(`${this.baseUrl}/api/v1/restriction/${id}`, id)
    }

    insert(restriction: Restriction) {
        return this.http.post(`${this.baseUrl}/api/v1/restriction/`, restriction)
    }
}