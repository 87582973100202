import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastService } from "../toast/toast.service";
import { BaseResponse } from "../../data/bases/base-response";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private bearerToken:string;

    constructor(
      private router: Router,
      private authService: NbAuthService,
      private toast:ToastService) {
      this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
          if (token.isValid()) {
            this.bearerToken = token.getValue()
          }
        });
    }

    private handleError(err: HttpErrorResponse): Observable<any> {
      var response:BaseResponse<any,any> = err.error

      if (err.status === 401 && this.router.url !== '/auth/login') {
        this.router.navigateByUrl(`/auth/logout`);
        return of(err.message);
      }

      if (err.status === 403) {
        this.router.navigateByUrl("/pages/404");
      }

      if (err.status === 500){
        if (response.errors) {
          for (let index = 0; index < response.errors.length; index++) {
            const element = response.errors[index];    
            this.toast.errorCode(element.code)
          }
        }

        return throwError(response);
      }

      return throwError(response);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const authReq = req.clone({headers: req.headers.set('Authorization', `Bearer ${this.bearerToken}`)});
      return next.handle(authReq).pipe(catchError(x=> this.handleError(x)));
    }
}
