import { Observable } from "rxjs";
import { BaseFilter } from "./bases/base-filter";
import { BaseModel } from "./bases/base-model";
import { BaseResponse } from "./bases/base-response";

export interface Module extends BaseModel {
  id?:string;
  name?:string;
  area?:string;
}

export interface ModuleFilter extends Module, BaseFilter {
}

export abstract class ModuleData {
  abstract insert(module:Module)
  abstract update(module:Module)
  abstract list(filter:ModuleFilter): Observable<BaseResponse<Module[],any>>
  abstract delete(id)
}
