import { ActionCheckboxComponent } from "./action-checkbox/action-checkbox.component";

export const BasicExampleMultiSelectComponent = {
  settings: {
    noDataMessage: "Não há registros",
    columns: {
      selected: {
        title: "",
        type: "custom",
        renderComponent: ActionCheckboxComponent,
        valuePrepareFunction: (cell, row) => row,
        onComponentInitFunction: (instance: ActionCheckboxComponent) => {
          instance.valueChange.subscribe(({ value, rowData }) => {
            rowData.selected = value;
          });
        },
      },
      title: {
        title: "Coluna",
      },
    },

    mode: "external",
    hideSubHeader: true,
    actions: {
      columnTitle: "",
      add: false,
      edit: false,
      delete: false,
      position: "right",
    },
  },
};
