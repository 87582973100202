import { Injectable } from "@angular/core";
import { Profiles, ProfilesData } from "../data/profiles";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";

@Injectable()
export class ProfilesService extends ProfilesData {
    private baseUrl = environment.services.accessControl.baseUrl;
  
    constructor(private http:HttpClient) { super() }
    
    list(filter:Profiles, pagination:any): Observable<BaseResponse<Profiles[],BaseResponsePagination>> {
        var params = new HttpParams()

        if(filter.name)
            params = params.set('name', filter.name)
        if(filter.personType)
            params = params.set('personType', filter.personType)

        return this.http.get<BaseResponse<Profiles[],BaseResponsePagination>>(`${this.baseUrl}/api/v1/profile`, { params })
    }
}
