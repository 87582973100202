import { Component, Input, OnInit, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Contact } from '../../../../@core/data/person';

@Component({
  selector: 'ngx-contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.scss']
})
export class ContactSelectComponent implements OnInit {
  submitted: boolean = false;
  contact: Contact = {
    name: '',
    document: '',
    cellphone: '',
    email: '',
    department: '',
    function: ''
  }
  @Input() contains: Contact[]
  @Input() inputContact: Contact
  @Input() state: string
  isAdded:boolean = false


  constructor(
    @Optional() protected ref: NbDialogRef<ContactSelectComponent>,
  ) { }

  ngOnInit() {
    if(this.inputContact) {
      this.contact = this.inputContact
    }
  }
  
  confirmContact(form: NgForm) {
    this.isAdded = false;

    if (this.state == "insert" && this.contains.find(c => c.name == this.contact.name && c.document == this.contact.document && c.cellphone == this.contact.cellphone && c.email == this.contact.email && c.department == this.contact.department && c.function == this.contact.function)) {
      this.isAdded = true;
      return;
    }
    
    this.ref.close(this.contact)
  }


  close() {
    this.ref.close()
  }
}
