import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";
import { Funcionality, FuncionalityData, FuncionalityFilter } from "../data/funcionality";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class FuncionalityService extends FuncionalityData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http:HttpClient) { super() }

  insert(funcionality:Funcionality){
    return this.http.post(`${this.baseUrl}/api/v1/funcionality/`,funcionality)
  }

  update(funcionality:Funcionality) {
    return this.http.put(`${this.baseUrl}/api/v1/funcionality/${module.id}/`,funcionality)
  }

  delete(id){
    return this.http.delete(`${this.baseUrl}/api/v1/funcionality/${id}/`,id)
  }

  list(filter:FuncionalityFilter): Observable<BaseResponse<Funcionality[],BaseResponsePagination>> {
    var params = setBaseFilter(filter)

    if (filter.id)
      params = params.set('id', filter.id)

    if (filter.module.id)
      params = params.set('moduleId', filter.module.id)

    if (filter.name)
      params = params.set('name', filter.name)

    if (filter.isActive)
      params = params.set('isActive', filter.isActive)

    return this.http.get<BaseResponse<Funcionality[],BaseResponsePagination>>(`${this.baseUrl}/api/v1/funcionality/`, { params })
  }
}
