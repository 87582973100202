import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ngx-action-checkbox",
  templateUrl: "./action-checkbox.component.html",
  styleUrls: ["./action-checkbox.component.scss"],
})
export class ActionCheckboxComponent {
  @Input() value: any;
  @Input() rowData: any;
  @Output() valueChange = new EventEmitter<{
    value: any;
    rowData: any;
  }>();

  constructor() {}

  toggle(value: boolean) {
    this.valueChange.emit({ value, rowData: this.rowData });
  }
}
