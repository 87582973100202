import { Injectable } from '@angular/core';
import { UserFilter } from '../data/user';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse } from '../data/bases/base-response';
import { Menu, MenuData } from '../data/menu';

@Injectable()
export class MenuService extends MenuData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http:HttpClient) { super() }

  getByUser(): Observable<BaseResponse<Menu[],null>> {
    return this.http.get<BaseResponse<Menu[],null>>(`${this.baseUrl}/api/v1/menu`)
  }
}
